import React from 'react';
import { useEffect } from 'react';
import useGlobalStore from '../../store/globalStore.js';

import useAmazonConnect from '../../hooks/useAmazonConnect.js';

function App({ children, amazonConnectConfig = {}, region = null }) {

    const { setRegion } = useGlobalStore();

    const {ChatWidgetButtonComponent} = useAmazonConnect(amazonConnectConfig);
    useEffect(() => {
        setRegion(region);
    }, [region]);

    return (<>
    {children}
    {ChatWidgetButtonComponent}
    </>);
}

export default App;
