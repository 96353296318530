import React, { useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import NavigationItemsHelper from "../helpers/NavigationItemsHelper";
import routes from "../constants/routes";


const rootElementId = 'amazon-connect-chat-widget';

const chatWidgetButton = {
    ariaLabels: {
        startChat: 'Start Chat',
        minimizeChat: 'Minimize Chat'
    }
};

const buildAndAppendScript = ({ id, url }) => {
    const script = document.createElement('script');
    script.id = id;
    script.src = url;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const defaultAmazonConnectFunction = function () {
        (window['amazon_connect'].ac = window['amazon_connect'].ac || []).push(arguments);
    };

    window['amazon_connect'] = window['amazon_connect'] || defaultAmazonConnectFunction;
};


const manageChatWidgetButtonVisibility = (navigationItems) => {

    const isActivePageFeedbackPage = NavigationItemsHelper.isActivePage(navigationItems, routes.FEEDBACK.ROUTE);

    const rootElement = document.getElementById(rootElementId);

    const elementSelector = 'button[aria-label="' + chatWidgetButton.ariaLabels.startChat + '"], button[aria-label="' + chatWidgetButton.ariaLabels.minimizeChat + '"]';
    const button = rootElement?.querySelector(elementSelector);

    if (button) {
        button.style.opacity = 1;

        if (button.getAttribute('aria-label') === chatWidgetButton.ariaLabels.startChat && isActivePageFeedbackPage) {
            button.style.opacity = 0;
        };

    }
};


export default function useAmazonConnect({ id, url, snippetId }) {


    const hasAllData = !!id && !!url && !!snippetId;

    const { navItems } = useSelector(state => state.main);
    const chatButtonRef = useRef();

    const setAmazonConnectScriptConfig = configOptions => {
        for (let key in configOptions) {
            if (config.hasOwnProperty(key)) {
                window['amazon_connect'](key, configOptions[key]);
            }
        }
    };

    const config = {
        customLaunchBehavior: {
            skipIconButtonAndAutoLaunch: true,
            alwaysHideWidgetButton: true,
            programmaticLaunch: function (launchCallback) {
                if (chatButtonRef.current) {
                    chatButtonRef.current.addEventListener("click", () => {
                        launchCallback();
                        chatButtonRef.current.style.display = 'none';
                        const closeWidgetButton = document.getElementById("amazon-connect-close-widget-button");
                        closeWidgetButton?.addEventListener("click", () => {
                            chatButtonRef.current.style.display = 'block';
                        });

                    });
                }
            },
        },

        styles: { iconType: 'CHAT', openChat: { color: '#ffffff', backgroundColor: '#E66C28' }, closeChat: { color: '#ffffff', backgroundColor: '#E66C28' } },
        snippetId: null,
        supportedMessagingContentTypes: ['text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response'],
        customStyles: {
            global: {
                frameWidth: '50vh',
                frameHeight: '60vh',
                textColor: '#000000',
                footerHeight: '70px',
                typeface: 'Verdana',
                headerHeight: '70px'
            },
            header: {
                headerTextColor: '#ffffff',
                height: '50px'
            },
            transcript: {
                messageFontSize: '16px',
                messageTextColor: '#000000'
            },
            footer: {
                buttonFontSize: '20px',
                buttonTextColor: '#000000',
            },

        }
    };

    useEffect(() => {

        if (!hasAllData) return;

        buildAndAppendScript({ id, url });

        config.snippetId = snippetId;

        setAmazonConnectScriptConfig(config);

        return () => {
            const script = document.getElementById(id);
            script.remove();
            delete window['amazon_connect'];
        };

    }, []);

    useEffect(() => {
        manageChatWidgetButtonVisibility(navItems);
    }, [navItems]);


    const button = hasAllData ? (
        <div
            ref={chatButtonRef}
            class="z-10 fixed right-0 bottom-0 w-32 md:w-40 rounded bg-gray-200 shadow-lg mr-2 mb-4 px-3 flex flex-col items-center">
            <p class="w-full text-sm pt-4 mb-2 leading-none">Can't find what you're looking for?</p>
            <button class="bg-orange-600 border py-2 md:py-0 rounded w-full mx-0 mb-3 md:py-0 px-0 flex justify-center items-center text-sm font-bold">
                <svg class="hidden md:inline-block fill-current text-current w-10 h-auto mr-1 -ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                    <path d="M10.6,17.6v19.8h2.7l0.1,5.9l6.1-5.9h2.9V35h-3.8l-2.8,2.7l0-2.7h-2.6V20h28.4v5.1h2.4v-7.5H10.6z"></path>
                    <path d="M24,26.6v15.3h19.1l3.6,3.8l0-3.8h2.1V26.6H24z M31.8,35.8c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7
                          C33.5,35,32.7,35.8,31.8,35.8z M36.4,35.8c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7
                          C38.1,35,37.4,35.8,36.4,35.8z M41,35.8c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7c1,0,1.8,0.8,1.8,1.7C42.8,35,42,35.8,41,35.8z"></path>
                </svg>
                <span>Let's Chat</span>
            </button>
        </div>

    ): null;

    return {
        ChatWidgetButtonComponent: button
    }

}









